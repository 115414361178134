import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { createFormData } from 'core/services/form';

/**
 * Retrieves all expense types.
 * @returns {Promise<Array>} The array of expense types.
 * @throws {Error} If an error occurs during the API request.
 * @example
 */
export const getTypes = async () => {
	try {
		const { data } = await executeAxios({
			url: '/expenses/types',
		});
		return data.data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de soorten onkosten.',
		);
		return [];
	}
};

/**
 * Adds a new expense or updates an existing expense.
 * @param {Object} newExpense - The new expense object.
 * @param {string} id - The ID of the expense to update (optional).
 * @returns {Promise<Object|null>} The created or updated expense object, or null if an error occurred.
 */
export const addExpense = async (newExpense, id) => {
	try {
		const { data } = await executeAxios({
			url: `/expenses${id ? '/' + id : ''}`,
			method: id ? 'PUT' : 'POST',
			data: createFormData(newExpense),
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		handleAlert('success', 'Onkosten zijn succesvol aangemaakt.');
		return data.data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het aanmaken van de onkosten.',
		);
		return null;
	}
};

/**
 * Deletes an expense.
 * @param {string} id - The ID of the expense to delete.
 * @returns {Promise<boolean>} A boolean indicating if the expense was successfully deleted.
 */
export const deleteExpense = async id => {
	try {
		const { status } = await executeAxios({
			url: `/expenses/${id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Onkosten zijn succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het verwijderen van de onkosten.',
		);
		return false;
	}
};

/**
 * Deletes an expense file attachment.
 * @param {string} id - The ID of the attachment to delete.
 * @returns {Promise<boolean>} A boolean indicating if the attachment was successfully deleted.
 */
export const deleteExpenseFile = async id => {
	try {
		const { status } = await executeAxios({
			url: `/expenses/attachments/${id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Bijlage is succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het verwijderen van de bijlage.',
		);
		return false;
	}
};

/**
 * Retrieves all expenses.
 * @returns {Promise<Array|null>} The array of expenses, or null if an error occurred.
 */
export const getAllExpenses = async () => {
	try {
		const { data } = await executeAxios({
			url: '/expenses',
		});
		return data.data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de onkosten.',
		);
		return null;
	}
};

/**
 * Retrieves all signed expenses.
 * @returns {Promise<Array|null>} The array of signed expenses, or null if an error occurred.
 */
export const getArchivedExpenses = async () => {
	try {
		const { data } = await executeAxios({
			url: '/expenses/archive',
		});
		return data.data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de gearchiveerde onkosten.',
		);
		return null;
	}
};

/**
 * Retrieves projects for a specific year and month.
 * @param {number} year - The year.
 * @param {number} month - The month.
 * @returns {Promise<Array>} The array of projects.
 */
export const getProjects = async (year, month) => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: `/timesheets/projects?year=${year}&month=${month}`,
		});
		return data.map(({ id, name }) => ({ id, name }));
	} catch (error) {
		console.log('error', error);
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de projecten.',
		);
	}
};
