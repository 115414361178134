import { useEffect, useState } from 'react';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { handleAlert } from 'core/services/actions';
import msalInstance from 'msalInstance';

import { InputCheckbox, InputField } from 'components/Form';
import Warning from 'components/Warning/Warning';

const CalendarSubscribe = () => {
	const baseUrl = `${process.env.REACT_APP_NODE_API_URL}/calendar/subscribe?account=${msalInstance.getActiveAccount().localAccountId}`;

	const [url, setUrl] = useState(`&type=events&type=trainings`);
	const [types, setTypes] = useState([
		{
			name: 'events',
			label: 'Evenementen waarop je bent uitgenodigd',
			value: true,
		},
		{
			name: 'trainings',
			label: 'Opleidingen waarop je bent uitgenodigd',
			value: true,
		},
		{
			name: 'birthday',
			label: 'Verjaardagen',
			value: false,
		},
		{
			name: 'service',
			label: 'Werkjubilea',
			value: false,
		},
	]);

	useEffect(() => {
		setUrl(
			`${baseUrl}${types
				.filter(x => x.value)
				.map(x => `&type[]=${x.name}`)
				.join('')}`,
		);
	}, [baseUrl, types]);

	const handleType = name => {
		setTypes(prevState =>
			prevState.map(x => {
				if (x.name !== name) return x;

				return {
					...x,
					value: !x.value,
				};
			}),
		);
	};

	const copyToClipbord = () => {
		navigator.clipboard
			.writeText(url)
			.then(() => handleAlert('success', 'Kopiëren gelukt'))
			.catch(() => handleAlert('danger', 'Kopiëren misslukt'));
	};

	return (
		<div style={{ marginTop: '5rem' }}>
			<Warning className='info' style={{ marginBottom: '3rem' }}>
				<b>Abonneer op jouw kalender</b>
				<br />
				Plak de link waarop jij je wil abonneren in het abonnementen
				onderdeel voor web- of internet-agenda&apos;s van jouw
				agenda-applicatie. Wil je weten hoe? Bekijk snel de handleiding
				van KetchUP.
			</Warning>

			<div className='form-field'>
				<label>
					<span>Op welke kalenders wil je abonneren?</span>
				</label>
			</div>
			{types.map(({ name, label, value }, i) => (
				<InputCheckbox
					label={label}
					name={name}
					value={value}
					onChange={() => handleType(name)}
					validate={() => ''}
					className={i === types.length - 1 ? 'last' : ''}
					key={name}
				/>
			))}

			<InputField
				onChange={() => ''}
				name='event_calendar'
				label='De abonnement-link'
				validate={() => ''}
				icon={faCalendar}
				description='Kopiëer de link en plak hem in je agenda-applicatie'
				button={{
					icon: faCopy,
					action: copyToClipbord,
				}}
				value={url}
				required
				readOnly
			/>
		</div>
	);
};

CalendarSubscribe.navigate = date => {
	return date;
};

CalendarSubscribe.title = () => {
	return 'Abonneer';
};

export default CalendarSubscribe;
