import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	faArrowLeftLong,
	faFileArchive,
	faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSubmit } from 'core/hooks/useSubmit';
import { downloadFile } from 'core/services/file';
import { ParseNumbersToHaveDecimalSeperators } from 'core/services/parse';

import {
	Button,
	Datepicker,
	InputCheckbox,
	InputField,
	Select,
	Textarea,
	Upload,
	useForm,
} from 'components/Form';
import { Col, Container, Row } from 'components/Grid';
import Loading from 'components/Loading/Loading';
import Table from 'components/Table/Table';

import InfoCard from '../InfoCard/InfoCard';

import {
	amountField,
	dateField,
	expenseTypeField,
	findTypeById,
	findTypeByName,
	projectIdField,
	remarkField,
	uploadField,
} from './expenses.form.helper';
import {
	addExpense,
	deleteExpense,
	getAllExpenses,
	getArchivedExpenses,
	getProjects,
	getTypes,
} from './expenses.service';

import './OnkostenView.scss';

export const OnkostenView = () => {
	const [expenseTypes, setExpenseTypes] = useState(null);
	const [expensesList, setExpensesList] = useState(null);
	const [archivedExpenses, setArchivedExpenses] = useState(null);
	const [projectList, setProjectList] = useState(null);
	const [editExpense, setEditExpense] = useState();
	const [loading, setLoading] = useState(true);

	const { id } = useParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const pageBaseUrl = `/${pathname
		.split('/')
		.filter(part => Number.isNaN(+part))
		.join('/')}`;

	useEffect(() => {
		getTypes()
			.then(setExpenseTypes)
			.then(getAllExpenses)
			.then(setExpensesList)
			.then(() => setLoading(false));
	}, []);

	const {
		register,
		values,
		reset,
		setValue,
		getValue,
		isDirty,
		isValid,
		unregister,
	} = useForm();

	const addExpenseToList = expense => {
		setExpensesList(prev => {
			return [...prev.filter(({ id }) => id !== expense.id), expense];
		});
	};

	const handleSubmit = useSubmit(async () => {
		const expense = await addExpense(
			{
				...values,
				attachments: values.attachments.filter(file => !file.id),
				date: values.date[0],
			},
			editExpense?.id,
		);
		if (expense) {
			if (!values.completed) {
				addExpenseToList(expense);
			}
			setEditExpense(null);
			reset();
			navigate(pageBaseUrl);
		}
	});

	const handleDelete = async id => {
		const deleted = await deleteExpense(id);
		if (deleted) {
			setExpensesList(prev => {
				return prev.filter(expense => expense.id !== id);
			});
		}
	};

	const handleChange = id => {
		if (editExpense) {
			addExpenseToList(editExpense);
		}
		const expense = expensesList.find(expense => expense.id === id);

		setEditExpense(expense);
		setExpensesList(prev => {
			return prev.filter(expense => expense.id !== id);
		});
		setValue('type', `${expense.expenseTypeId}`);
		setValue('amount', expense.amount);
		setValue('completed', expense.completed || 0);
		setValue(
			'attachments',
			expense.attachments.map(file => ({
				id: file.id,
				name: file.description,
				type: file.mime,
			})) || [],
		);
		setValue('remark', expense.remark || '');
		setValue('date', [expense.date]);

		if (expense.projectId) {
			loadProjects().then(() => {
				setValue('projectId', expense.projectId);
			});
		}
	};

	const handleCancel = () => {
		if (editExpense) {
			addExpenseToList(editExpense);
			setEditExpense(null);
		}
		reset();
		navigate(pageBaseUrl);
	};

	const loadProjects = async () => {
		if (!projectList) {
			const date = getValue('date')[0]
				? new Date(getValue('date')[0])
				: new Date();
			const projects = await getProjects(
				date.getFullYear(),
				date.getMonth() + 1,
			);
			setProjectList(projects);
			return projects;
		}
	};

	const isIdForOption = (optionName, id) => {
		return findTypeByName(expenseTypes, optionName)?.id === +id;
	};

	const isSelectedValue = optionName => {
		return isIdForOption(optionName, values.type);
	};

	const handleTypeChange = value => {
		if (isIdForOption('Onkosten project', value)) {
			loadProjects().then(projects => {
				if (projects?.length === 1) {
					setValue('projectId', projects[0].id);
				}
			});
		} else {
			unregister('projectId');
		}
	};

	const isAttachmentNeeded = () => {
		return findTypeById(expenseTypes, values.type)?.needsAttachment;
	};

	useEffect(() => {
		if (id && expensesList) {
			const expense = expensesList.find(expense => expense.id == id);
			if (expense) {
				handleChange(expense.id);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, expensesList]);

	const handleArchiveClick = async () => {
		if (!archivedExpenses) {
			const expenses = await getArchivedExpenses();
			setArchivedExpenses(expenses);
		}
	};

	const handleClickBack = () => {
		setArchivedExpenses(null);
	};

	return loading ? (
		<Loading />
	) : expensesList && expenseTypes ? (
		<section className='expenses'>
			{archivedExpenses ? (
				<section className='expenses-archive'>
					<h5
						className='expenses-archive__go-back'
						onClick={handleClickBack}>
						<FontAwesomeIcon
							icon={faArrowLeftLong}
							className='icon'
							fixedWidth
						/>{' '}
						Terug naar onkosten
					</h5>
					<Table
						headers={{
							date: 'Datum',
							type: 'Type',
							amount: 'Bedrag',
							kwh: 'kWh',
							remark: 'Opmerking',
							attachments: 'Bijlagen',
						}}
						data={archivedExpenses.map(expense => ({
							date: new Date(expense.date).toLocaleDateString(
								'nl-BE',
								{
									year: 'numeric',
									month: 'short',
									day: 'numeric',
								},
							),
							type: findTypeById(
								expenseTypes,
								expense.expenseTypeId,
							)?.name,
							amount: expense.amount ? `€ ${expense.amount}` : '',
							kwh: expense.kwh ? `${expense.kwh} kWh` : 'n.v.t.',
							remark: expense.remark,
							attachments: expense.attachments?.map(file => (
								<a
									key={file.filename}
									href=''
									onClick={e => {
										e.preventDefault();
										downloadFile(
											file.filename,
											file.mime,
											file.description,
										);
									}}
									target='_blank'
									rel='noreferrer'>
									<FontAwesomeIcon icon={faFileArchive} />
								</a>
							)),
						}))}
					/>
				</section>
			) : (
				<>
					{expensesList.length > 0 ? (
						<section className='expenses-list'>
							{editExpense ? null : (
								<section className='expenses-list__items'>
									{expensesList.map((expense, i) => (
										<InfoCard
											key={`${expense.id}${i}`}
											title={
												findTypeById(
													expenseTypes,
													expense.expenseTypeId,
												)?.name
											}
											subtitle={`${expense.expenseTypeId == 5 ? 'kWh' : '€'} ${ParseNumbersToHaveDecimalSeperators(
												expense.amount,
											)}`}
											infoIcon={faFileInvoiceDollar}
											handleDelete={() =>
												handleDelete(expense.id)
											}
											handleChange={() =>
												navigate(
													`${pageBaseUrl}/${expense.id}`,
												)
											}
										/>
									))}
								</section>
							)}
							<section className='expenses-list__sign' />
						</section>
					) : null}
					<section className='expenses-form'>
						<Container>
							<Row>
								<Col lg={6} md={12}>
									<div className='form-field'>
										<Datepicker
											{...register('date', dateField)}
											mode='single'
										/>
										<div className='info'>
											Selecteer de datum op het
											onkostenbewijs (of de laatste dag
											van de maand indien een
											onkostenbewijs niet beschikbaar is)
										</div>
									</div>
								</Col>

								<Col lg={6} md={12}>
									<Select
										{...register('type', {
											...expenseTypeField,
											onChange: handleTypeChange,
										})}
										options={expenseTypes.map(type => ({
											value: `${type.id}`,
											label: type.name,
										}))}
									/>

									<InputField
										{...register('amount', {
											...amountField,
											...(isSelectedValue(
												'Kosten laadpaal',
											) && {
												label: 'kWh',
												placeholder: '0,00 kWh',
												step: '0.01',
											}),
										})}
										reset={!isDirty() ? !editExpense : null}
									/>

									{isSelectedValue('Onkosten project') &&
									projectList?.length > 1 ? (
										<Select
											{...register('projectId', {
												...projectIdField,
												...(!isSelectedValue(
													'Onkosten project',
												) && { validations: [] }),
												options: projectList.map(
													project => ({
														value: project.id,
														label: project.name,
													}),
												),
											})}
										/>
									) : null}
								</Col>
							</Row>
							<Row>
								<Col lg={6} md={12}>
									<Upload
										{...register(
											'attachments',
											uploadField,
										)}
									/>
								</Col>
								<Col lg={6} md={12}>
									<Textarea
										{...register('remark', {
											...remarkField,
											description: `Nog ${50 - values.remark?.length} karakters beschikbaar.`,
											validateOnChange: true,
										})}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={12}>
									<InputCheckbox
										{...register('completed', {
											value: 0,
											disabled:
												isAttachmentNeeded() &&
												values.attachments?.length ===
													0,
											label: 'Deze onkostennota is volledig',
											description:
												'De onkostennota is pas volledig met een onkostenbewijs. Een melding die volledig is, kan niet meer worden aangepast. Let erop dat je alle benodigde bijlagen en informatie hebt opgeladen alvorens deze checkbox aan te vinken.',
										})}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={4}>
									<div className='btn__group'>
										<Button
											buttonStyle='secondary'
											onClick={handleArchiveClick}>
											Archief
										</Button>
									</div>
								</Col>
								<Col
									lg={8}
									className='expenses-form__group-right'>
									<div className='btn__group'>
										{isDirty() ? (
											<Button
												buttonStyle='secondary'
												onClick={handleCancel}>
												Annuleren
											</Button>
										) : null}
										<Button
											buttonStyle='primary'
											disabled={!isValid()}
											onClick={handleSubmit}>
											Bewaren
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				</>
			)}
		</section>
	) : (
		<div className='timesheet__message'>
			Er is iets misgegaan. Probeer later opnieuw.
		</div>
	);
};
