import React, { useEffect, useRef, useState } from 'react';
import { centerCrop, makeAspectCrop, ReactCrop } from 'react-image-crop';
import { executeAxios } from 'core/services/actions/axios.service';
import { createFormData } from 'core/services/form';
import { updateUserData } from 'core/store/authentication/auth.actions';

import { Button, Upload, useForm } from 'components/Form';
import Loader from 'components/Loading/Loading';

import './UpdatePicture.scss';
import 'react-image-crop/dist/ReactCrop.css';

export const UpdatePicture = () => {
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(null);
	const [crop, setCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState(null);

	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);

	const { register, setValue } = useForm({
		onFormChange: values => {
			const image = values.uploadImage[0];
			if (image) {
				setImage(image);
			}
			const reader = new FileReader();
			reader.readAsDataURL(image);
			reader.onloadend = () => {
				imgRef.current.src = reader.result;
			};
		},
	});

	const sendImage = async () => {
		setLoading(true);
		const canvas = previewCanvasRef.current;
		const base64Image = canvas.toDataURL('image/jpeg', 1);
		var blobBin = atob(base64Image.split(',')[1]);
		var array = [];
		for (var i = 0; i < blobBin.length; i++) {
			array.push(blobBin.charCodeAt(i));
		}
		var blob = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
		const file = new File([blob], image.name, { type: blob.type });

		try {
			const {
				data: { status, data },
			} = await executeAxios({
				method: 'PUT',
				url: '/auth/me/image',
				data: createFormData({ image: file }),
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			if (status === 'success') {
				setImage(null);
				setCrop(null);
				setValue('uploadImage', []);
				updateUserData({ thumbnail: data });
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onImageLoad = e => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
		document.querySelector('.crop-container').scrollIntoView({
			behavior: 'instant',
		});
		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 90,
				},
				1,
				width,
				height,
			),
			width,
			height,
		);

		setCrop(crop);
	};

	function setCanvasImage(image, canvas, crop) {
		if (!crop || !canvas || !image) {
			return;
		}

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');
		const pixelRatio = window.devicePixelRatio;

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.fillStyle = 'white';
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY,
		);
	}

	const handleCancel = () => {
		setValue('uploadImage', []);
		setImage(null);
		setCrop(null);
	};

	useEffect(() => {
		setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
	}, [completedCrop]);

	return (
		<>
			<div className='upload-picture'>
				{loading ? (
					<Loader />
				) : !image ? (
					<Upload
						{...register('uploadImage', {
							type: 'file',
							label: 'Upload een profielfoto',
							name: 'attachments',
							multiple: true,
							accept: 'image/png, image/jpeg',
							required: true,
							description:
								'Enkel afbeeldingen kleiner dan 10MB zijn toegelaten',
							value: [],
						})}
					/>
				) : (
					<>
						<div className='crop-container'>
							<ReactCrop
								crop={crop}
								aspect={1}
								onComplete={crop => {
									setCompletedCrop(crop);
								}}
								keepSelection={true}
								onChange={(_, percentageCrop) =>
									setCrop(percentageCrop)
								}>
								<img
									ref={imgRef}
									onLoad={onImageLoad}
									alt='Crop me'
								/>
							</ReactCrop>
							<canvas hidden ref={previewCanvasRef} />
						</div>
						<div className='buttons'>
							<Button buttonStyle='primary' onClick={sendImage}>
								Opslaan
							</Button>
							<Button
								buttonStyle='secondary'
								onClick={handleCancel}>
								Annuleer
							</Button>
						</div>
					</>
				)}
			</div>
			<div className='upload-picture__info'>
				Deze functie is alleen beschikbaar in portretmodus
			</div>
		</>
	);
};
