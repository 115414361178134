import { useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'core/hooks/ThemeProvider';
import { arraySum } from 'core/services/array';
import { toShortDate } from 'core/services/date';

import Modal from 'components/Modal/Modal';

import { getHtmlElement } from '../vacation.service';
import { AbsenceTypes } from '../VacationModal/vacation.modal.helper';

import './VacationCounterModal.scss';

const VacationCounterModal = ({ modal, setModal }) => {
	const {
		data: { counters, requested, available, date },
	} = modal;

	const { theme } = useTheme();
	const ref = useRef(null);

	const [isPopoverInfo, setIsPopoverInfo] = useState({});
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isPopoverCountersOpen, setIsPopoverCountersOpen] = useState(false);

	const parseCounters = ({ type, prediction, absences }) => {
		const counter = {
			label: type?.name,
			name: type?.machineName,
			absences: absences,
			currentCounter: prediction?.counter || 0,
			extra: [
				{ label: 'Startteller', value: prediction?.startCounter || 0 },
				{
					label: 'Opgenomen',
					value: arraySum(absences || [], 'duration'),
				},
			],
		};

		if (type.machineName === AbsenceTypes.BANK_HOLIDAY) {
			const taken =
				arraySum(
					prediction.prediction?.pastHolidays || [],
					'duration',
				) * (type.isHourly ? 1 : 8);

			counter.currentCounter = prediction.prediction?.availableNow;
			counter.extra = [
				{ label: 'Startteller', value: 0 },
				{
					label: 'Opgebouwd',
					value: taken + prediction.prediction?.availableNow || 0,
				},
				{ label: 'Opgenomen', value: taken },
				...(prediction.prediction?.freeHolidays
					?.filter(feestdag => !feestdag.availableNow)
					.map(feestdag => ({
						label: `${feestdag.name}: tegoed vanaf ${toShortDate(
							feestdag.asOff,
						)}`,
						value: feestdag.counter,
					})) ?? []),
				...(prediction.prediction?.upcomingHolidays
					?.filter(x => x.typeName === 'Feestdag')
					?.map(feestdag => ({
						label: `${feestdag.name}: geboekt op ${toShortDate(
							feestdag.date,
						)}`,
						value: null, //feestdag.duration * (type.isHourly ? 1 : 8),
					})) ?? []),
			];
		} else if (type.machineName === AbsenceTypes.ADV) {
			const taken = arraySum(absences || [], 'duration');
			counter.currentCounter = prediction.counter?.toFixed(2);
			counter.extra = [
				{ label: 'Startteller', value: prediction?.startCounter || 0 },
				{ label: 'Opgebouwd', value: taken + prediction?.counter || 0 },
				{
					label: 'Opgenomen',
					value: taken,
				},
				{
					label: 'Voorspelde opbouw dit jaar',
					value: prediction.prediction?.predicted,
				},
			];
		} else if (type.machineName === AbsenceTypes.SENIORITY_LEAVE) {
			counter.extra = [
				{ label: 'Startteller', value: prediction?.startCounter || 0 },
				...(prediction.prediction?.build
					? [
							{
								label: 'Opgebouwd',
								value: prediction.prediction?.build,
							},
						]
					: []),
				{
					label: 'Opgenomen',
					value: arraySum(absences || [], 'duration'),
				},
				...(prediction.prediction?.asOff
					? [
							{
								label: `Tegoed vanaf ${prediction.prediction?.asOff}`,
								value: prediction.prediction?.predicted,
							},
						]
					: []),
			];
		} else if (type.machineName === AbsenceTypes.OVERTIME) {
			const taken = arraySum(absences || [], 'duration');
			counter.currentCounter = prediction.counter?.toFixed(2);
			counter.extra = [
				{ label: 'Startteller', value: prediction?.startCounter || 0 },
				{
					label: 'Opgebouwd',
					value:
						taken +
						(prediction?.counter || 0) -
						(prediction?.startCounter || 0),
				},
				{
					label: 'Opgenomen',
					value: taken,
				},
			];
		} else if (type.machineName === AbsenceTypes.RECOVERY_LEAVE) {
			counter.extra = prediction.prediction?.availability?.map(
				availability => ({
					label: `Geldig tot ${toShortDate(availability.date)}`,
					value: availability.counter,
				}),
			);
		}

		return counter;
	};

	const showExtraInfo = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	return (
		<Modal
			ref={ref}
			show={!!modal}
			title='Vakantieteller'
			small
			subTitle={`situatie op ${toShortDate(date)}`}
			close={() => setModal(null)}
			className='vacation-modal'
			actions={[
				{
					label: (
						<Popover
							isOpen={isPopoverOpen}
							positions={['top']}
							boundaryInset={95}
							padding={2}
							content={({ position, childRect, popoverRect }) => (
								<ArrowContainer
									position={position}
									childRect={childRect}
									popoverRect={popoverRect}
									arrowColor='transparent'
									arrowSize={10}
									className='popover-arrow-container'
									arrowClassName='popover-arrow'>
									<section className='vacation-info-popover'>
										<div>
											Vakantietellers zijn weergegeven in
											uren.
											<br />
											De voorspelde ADV-opbouw is een
											indicatie en wordt berekend op basis
											van de duurtijd van je huidige
											project(en). Het effectief
											opgebouwde ADV kan afwijken van de
											voorspelling.
											<br />
											Indien je project eindigt, zal eerst
											ADV worden afgerekend gedurende de
											periode dat je op de bank zit. Je
											voorspelde teller houdt hier geen
											rekening mee net zoals er geen
											rekening wordt gehouden met ziekte.
											<br />
											ADV wordt opgebouwd per werkuur. Het
											is mogelijk dat je meer of minder
											dan 8 uur per maand opbouwt. Je hebt
											wel altijd recht op 96 uur per jaar
											tenzij je ziek bent.
											<br />
											<br />
											Vakantietypes worden bepaald tijdens
											de indiening van je timesheet
											volgens beschikbaarheid. Er wordt
											rekening gehouden met de ADV die je
											opbouwt tijdens de registratie, er
											wordt geen rekening gehouden met de
											voorspelling.
											<br />
											Als je meer afwezigheid wil indienen
											dan beschikbaar, zal een onbetaalde
											afwezigheid worden geregistreerd.
										</div>
									</section>
								</ArrowContainer>
							)}
							containerClassName={`popover popover-z theme--${theme}`}>
							<div
								className='popover-icon theme'
								onMouseOver={() => {
									setIsPopoverOpen(true);
								}}
								onMouseLeave={() => setIsPopoverOpen(false)}>
								<FontAwesomeIcon
									icon={faInfoCircle}
									size='xl'
								/>
							</div>
						</Popover>
					),
					action: showExtraInfo,
					buttonType: 'link',
					styles: { float: 'left' },
				},
			]}>
			<section className='vacation-counters'>
				<section className='counters'>
					{counters
						? counters.map(parseCounters).map((counter, i) => {
								return (
									<div className='counter' key={i}>
										<div className='counter__main'>
											<span className='counter__label'>
												{counter.label}
											</span>
											<span className='counter__value'>
												{(+counter.currentCounter)
													.toFixed(2)
													.replace('.', ',')}{' '}
												u
											</span>
										</div>
										{counter.extra
											? counter.extra.map((item, j) => (
													<div
														className='counter__info'
														key={`${i}${j}`}>
														<span className='counter__info__label'>
															{item.label}
															{item.label ===
															'Opgenomen' ? (
																<Popover
																	isOpen={
																		isPopoverInfo[
																			counter
																				.name
																		] ??
																		false
																	}
																	positions={[
																		'right',
																	]}
																	boundaryInset={
																		95
																	}
																	padding={2}
																	content={({
																		position,
																		childRect,
																		popoverRect,
																	}) => (
																		<ArrowContainer
																			position={
																				position
																			}
																			childRect={
																				childRect
																			}
																			popoverRect={
																				popoverRect
																			}
																			arrowColor='transparent'
																			arrowSize={
																				10
																			}
																			className='popover-arrow-container'
																			arrowClassName='popover-arrow'>
																			<section className='vacation-info-popover'>
																				{counter
																					.absences
																					.length ? (
																					counter.absences.map(
																						absence => (
																							<div>
																								<span>
																									{toShortDate(
																										absence.date,
																									)}
																								</span>
																								<span>
																									{absence.duration
																										.toFixed(
																											2,
																										)
																										.replace(
																											'.',
																											',',
																										)}{' '}
																									u
																								</span>
																							</div>
																						),
																					)
																				) : (
																					<div className=''>
																						Nog
																						niet
																						opgenomen
																					</div>
																				)}
																			</section>
																		</ArrowContainer>
																	)}
																	containerClassName={`popover popover-z theme--${theme}`}>
																	<div
																		className='popover-icon'
																		onMouseOver={() => {
																			setIsPopoverInfo(
																				prevState => ({
																					...prevState,
																					[counter.name]: true,
																				}),
																			);
																		}}
																		onMouseLeave={() =>
																			setIsPopoverInfo(
																				prevState => ({
																					...prevState,
																					[counter.name]: false,
																				}),
																			)
																		}>
																		<FontAwesomeIcon
																			icon={
																				faInfoCircle
																			}
																		/>
																	</div>
																</Popover>
															) : null}
														</span>
														<span className='counter__info__value'>
															{item.value !== null
																? `${item.value
																		.toFixed(
																			2,
																		)
																		.replace(
																			'.',
																			',',
																		)} u`
																: ''}
														</span>
													</div>
												))
											: null}
									</div>
								);
							})
						: null}
				</section>
				<section className='total'>
					<div className='total__requested'>
						<span>Aangevraagd</span>
						<span>
							{(requested || 0).toFixed(2).replace('.', ',')} u
						</span>
					</div>
					<div
						className={`total__available--${
							available >= 0 || !available
								? 'positive'
								: 'negative'
						}`}>
						<span>
							Beschikbaar
							<Popover
								isOpen={isPopoverCountersOpen}
								positions={['top']}
								boundaryInset={95}
								padding={2}
								content={({
									position,
									childRect,
									popoverRect,
								}) => (
									<ArrowContainer
										position={position}
										childRect={childRect}
										popoverRect={popoverRect}
										arrowColor='transparent'
										arrowSize={10}
										className='popover-arrow-container'
										arrowClassName='popover-arrow'>
										<section className='vacation-info-popover'>
											{getHtmlElement(
												counters,
												requested || 0,
											)}
										</section>
									</ArrowContainer>
								)}
								containerClassName={`popover popover-z theme--${theme}`}>
								<div
									className='popover-icon'
									onMouseOver={() => {
										setIsPopoverCountersOpen(true);
									}}
									onMouseLeave={() =>
										setIsPopoverCountersOpen(false)
									}>
									<FontAwesomeIcon icon={faInfoCircle} />
								</div>
							</Popover>
						</span>
						<span>
							{(available || 0).toFixed(2).replace('.', ',')} u
						</span>
					</div>
				</section>
			</section>
		</Modal>
	);
};

export default VacationCounterModal;
