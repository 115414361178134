import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	endOfMonth,
	isBefore,
	isSameMonth,
	toFullMonthYear,
	toShortDate,
} from 'core/services/date';
import { isAfter } from 'date-fns';

import { Button } from 'components/Form';

import { downloadTimesheet, signTimesheet } from './timesheetOverview.service';
import TimesheetOverviewItemLine from './TimesheetOverviewItemLine';

const TimesheetOverviewItem = ({
	date,
	projects,
	bikeMileages,
	carMileages,
	setModal,
	loadTimesheets,
	lastApproved,
	lastSigned,
}) => {
	const handleSignTimesheets = async () => {
		await signTimesheet(date);
		await loadTimesheets();
	};

	const [signed, setSigned] = useState(true);

	const handleDownloadTimesheet = async (fileId, mimetype, projectName) => {
		const filename = `${projectName}_${toFullMonthYear(date)}.${
			mimetype.split('/')[1]
		}`
			.replace(/\s/g, '_')
			.toLowerCase();
		await downloadTimesheet(fileId, filename);
	};

	useEffect(() => {
		let signed = true;
		if (projects.length) {
			projects.map(project => {
				signed = signed && project.signed;
			});
		} else {
			signed =
				!isSameMonth(lastApproved, date) && isAfter(lastSigned, date);
		}
		setSigned(signed);
	}, [date, lastApproved, lastSigned, projects]);

	return (
		<div className='timesheet-overview-item'>
			<div className='timesheet-overview-item__info'>
				<p className='timesheet-overview-item__title'>
					{toFullMonthYear(date)}
				</p>
				<div className='timesheet-overview-item__content'>
					{projects.length
						? projects.map(
								({
									project,
									signedEmp,
									signedClient,
									filename,
									mimeType,
								}) => (
									<TimesheetOverviewItemLine
										key={project.id}
										title={project.name}
										noTimesheet={project.noTimesheet}
										upTimesheet={project.upTimesheet}
										filename={filename}
										signed={{
											emp: signedEmp,
											client: signedClient,
										}}
										action={() =>
											handleDownloadTimesheet(
												filename,
												mimeType,
												project.name,
											)
										}
									/>
								),
							)
						: 'Geen actieve projecten'}
					{bikeMileages.total ? (
						<TimesheetOverviewItemLine
							title='Onkostennotta fietsvergoeding'
							value={`€ ${bikeMileages.total.toFixed(2)}`}
						/>
					) : null}
					{carMileages.total ? (
						<TimesheetOverviewItemLine
							title='Onkostennotta autovergoeding'
							value={`€ ${carMileages.total.toFixed(2)}`}
						/>
					) : null}
				</div>
				<div className='timesheet-overview-item__registration-note'>
					<Link
						to='/timesheet/tekenen/registratienota'
						state={{
							year: new Date(date).getFullYear(),
							month: new Date(date).getMonth() + 1,
						}}>
						Registratienota
					</Link>
				</div>
			</div>
			<div className='timesheet-overview-item__footer'>
				<div className='timesheet-overview-item__actions'>
					{projects.some(({ project }) => project.billable) ? (
						<Button
							buttonStyle='secondary'
							onClick={() =>
								setModal({
									date,
									projects: projects
										.filter(
											({ project }) => project.billable,
										)
										.map(
											({
												project,
												signedEmp,
												signedClient,
											}) => ({
												project,
												signedEmp,
												signedClient,
											}),
										),
								})
							}
							disabled={signed}>
							Timesheet opladen
						</Button>
					) : null}
					<Button
						buttonStyle='primary'
						onClick={handleSignTimesheets}
						disabled={signed}>
						Maand tekenen
					</Button>
				</div>
				<span className='timesheet-overview-item__signed'>
					{signed ? (
						<>
							Ingediende records getekend.
							<br />
							Laatst getekend:{' '}
							{isSameMonth(lastSigned, date) ||
							isBefore(lastSigned, date)
								? toShortDate(lastSigned)
								: endOfMonth(date)}
							.
						</>
					) : (
						<>
							Laatst ingediend: {toShortDate(lastSigned)}
							.<br />
							Je kan tekenen tot{' '}
							{isSameMonth(lastApproved, date)
								? toShortDate(lastApproved)
								: endOfMonth(date)}
						</>
					)}
				</span>
			</div>
		</div>
	);
};

export default TimesheetOverviewItem;
