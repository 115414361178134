import React, { useEffect, useState } from 'react';
import { toShortDate } from 'core/services/date';
import { isRequired } from 'core/services/form';

import { InputCheckbox, Select, useField, useForm } from 'components/Form';

import './TrainingFormDay.scss';

const TrainingFormDay = ({ day, order, toppings, setFormData }) => {
	const [toppingList] = useState(() =>
		toppings?.map(topping => ({
			value: topping.id,
			label: topping.topping,
		})),
	);

	const form = useForm();
	const bread = useField({
		label: 'Ik neem geen broodje',
		value: order ? !order.topping : false,
	});

	const toppingOptions = {
		placeholder: 'Topping',
		options: toppingList,
		required: true,
		errors: [],
		validations: [isRequired],
		value: order?.topping || '',
	};

	const sizeOptions = {
		placeholder: 'Grootte',
		options: [
			{ value: 'small_white', label: 'Klein Wit' },
			{ value: 'large_white', label: 'Groot Wit' },
			{ value: 'small_dark', label: 'Klein Bruin' },
			{ value: 'large_dark', label: 'Groot Bruin' },
		],
		required: true,
		errors: [],
		validations: [isRequired],
		value: order?.size || '',
	};

	const extraOptions = {
		label: 'Smos',
		validations: [],
		errors: [],
		value: order ? !!order?.extra : false,
		required: false,
	};

	const valid = form.isValid();

	useEffect(() => {
		setFormData(prev => [
			...prev.filter(item => item.date !== day.date),
			{ date: day.date, ...form.values, valid },
		]);
	}, [form.values, valid, setFormData, day]);

	useEffect(() => {
		if (bread.value) {
			setFormData(prev => [
				...prev.filter(item => item.date !== day.date),
				{ date: day.date, valid: true, bread: bread.value },
			]);
		}
	}, [bread.value, setFormData, day]);

	const findTopping = toppingId => {
		return toppings.find(topping => topping.id === toppingId);
	};

	return (
		<div className='form-day'>
			<h2>{toShortDate(day.date)}</h2>

			{day.bread ? (
				<>
					<InputCheckbox {...bread} name='bread' />

					{!bread.value ? (
						<>
							<Select
								{...form.register('topping', toppingOptions)}
							/>
							<span>
								{
									findTopping(form.getValue('topping'))
										?.description
								}
							</span>

							<Select {...form.register('size', sizeOptions)} />
							{findTopping(form.getValue('topping'))
								?.hasOptions ? (
								<InputCheckbox
									{...form.register('extra', extraOptions)}
								/>
							) : null}
						</>
					) : null}
				</>
			) : (
				<p>{day.reason}</p>
			)}
		</div>
	);
};

export default TrainingFormDay;
