import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';

let defaultState = {
	calendar: [],
	calendarLoading: false,
};

const loadCalendar = (state, { calendarLoading }) => ({
	...state,
	calendarLoading,
});

const loadCalendarCommit = (state, { data }) => {
	return {
		...state,
		calendar: data,
		calendarLoading: false,
	};
};

export const calendarReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.CALENDAR_LOAD:
			return loadCalendar(state, payload);
		case ACTION_TYPES.CALENDAR_LOAD_COMMIT:
			return loadCalendarCommit(state, payload);
		default:
			return state;
	}
};
