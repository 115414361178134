import { useState } from 'react';
import { useSelector } from 'react-redux';
import { faHeartPulse, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Card from 'components/Card/Card';
import { Button } from 'components/Form';

import CreateWorkoutModal from '../CreateWorkoutModal/CreateWorkoutModal';

const MovingMissionSidebar = () => {
	const [modal, setModal] = useState(null);
	const { movingMission, types } = useSelector(
		state => state.movingMissionReducer,
	);

	return (
		<div className='moving-mission-sidebar'>
			<Card icon={faHeartPulse} title={null}>
				<section className='moving-mission-sidebar__content'>
					<section className='moving-mission-sidebar__summary'>
						<h3>Je samenvatting</h3>
						<SidebarListItem
							text='Totaal aantal punten'
							value={movingMission?.personalRanking?.points}
						/>
						<SidebarListItem
							text='Jouw plaats op de ranglijst'
							value={movingMission?.personalRanking?.placement}
						/>
						{movingMission?.personalRanking?.previous ? (
							<SidebarListItem
								text={`Verschil met plaats ${movingMission?.personalRanking?.placement - 1}`}
								value={
									movingMission?.personalRanking?.previous -
									movingMission?.personalRanking?.points
								}
							/>
						) : null}
					</section>
					<section className='moving-mission-sidebar__today'>
						<h3>Vandaag</h3>
						<SidebarListItem
							text='Minuten gesport'
							value={movingMission?.info?.workoutMinutes}
						/>
						<SidebarListItem
							text='Punten verdiend'
							value={movingMission?.info?.pointsEarned}
						/>
						<SidebarListItem
							text='Aantal dagen'
							value={movingMission?.info?.streakDays}
						/>
						<SidebarListItem
							text='Huidige bonus'
							value={movingMission?.info?.currentBonus}
						/>
					</section>
				</section>
				{movingMission?.readonly ? null : (
					<Button
						buttonStyle='primary'
						block
						onClick={() =>
							setModal({
								types,
								enableFreeze: movingMission?.enableFreeze,
							})
						}>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp;minuten toevoegen
					</Button>
				)}
			</Card>
			{modal ? (
				<CreateWorkoutModal modal={modal} setModal={setModal} />
			) : null}
		</div>
	);
};

export default MovingMissionSidebar;

const SidebarListItem = ({ text, value }) => {
	return (
		<p className='moving-mission-sidebar__line'>
			<span className='moving-mission-sidebar__line--text'>
				{text}:&nbsp;
			</span>
			<span className='moving-mission-sidebar__line--value'>{value}</span>
		</p>
	);
};
