import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import userTemplate from 'assets/images/user.png';
import { loadCoces } from 'core/store/coces/coces.actions';
import { loadDepartments } from 'core/store/departments/departments.actions';
import { loadUsers } from 'core/store/users/users.actions';

import Card from 'components/Card/Card';
import { Select } from 'components/Form/FormInput';
import { Col, Container, Row } from 'components/Grid';
import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';

import './EmployeeOverview.scss';

const EmployeeOverview = () => {
	const { authUser } = useSelector(state => state.authReducer);
	const { coces, cocesLoading } = useSelector(state => state.cocesReducer);
	const { departments, departmentsLoading } = useSelector(
		state => state.departmentsReducer,
	);
	const { users, usersLoading } = useSelector(state => state.usersReducer);

	const combinedOptions = [
		{ value: 'ALL', label: 'Iedereen', source: 'Iedereen' },
		{ value: 'COCECOS', label: `CoCeCo's`, source: 'COCECOS' },
		{
			label: 'Departementen',
			options: [
				...departments.map(({ name }) => ({
					value: name,
					label: name,
					source: 'Department',
				})),
			],
		},
		{
			label: `CoCe's`,
			options: [
				...coces.map(({ name }) => ({
					value: name,
					label: name === 'AND' ? 'STAF' : name,
					source: 'COCE',
				})),
			],
		},
	];

	const [searchText, setSearchText] = useState('');
	const [prevSelect, setPrevSelect] = useState();
	const [selectedCombinedOption, setSelectedCombinedOption] = useState({
		value: authUser?.coces.length > 0 ? authUser?.coces[0] : 'ALL',
		label: authUser?.coces.length > 0 ? authUser?.coces[0] : 'Iedereen',
		source: 'COCE',
	});
	const [employees, setEmployees] = useState([]);

	const handleFilter = () => {
		let filteredUsers = [];

		if (searchText.length !== 0) {
			if (!prevSelect) {
				setPrevSelect(selectedCombinedOption);
				setSelectedCombinedOption({ value: 'ALL', label: 'Iedereen' });
			}

			const sanitizeText = text => text.replace(/\s/g, '').toLowerCase();

			filteredUsers = users.filter(user => {
				const fullName = sanitizeText(
					`${user.firstName}${user.lastName}`,
				);
				const query = sanitizeText(searchText);

				return fullName.includes(query);
			});
		} else {
			if (prevSelect) {
				setSelectedCombinedOption(prevSelect);
				setPrevSelect();
			}

			switch (selectedCombinedOption.value) {
				case 'ALL':
					filteredUsers = users;
					break;
				case 'COCECOS':
					filteredUsers = coces.flatMap(coce => coce.cocecos);
					filteredUsers = [
						...new Map(
							filteredUsers.map(coceco => [coceco.id, coceco]),
						).values(),
					];
					break;
				default:
					filteredUsers = users.filter(user => {
						return selectedCombinedOption.source === 'COCE'
							? user.coces.includes(selectedCombinedOption.value)
							: user.department === selectedCombinedOption.value;
					});
					break;
			}
		}

		setEmployees(filteredUsers);
	};

	useEffect(() => {
		async function fetchData() {
			if (!cocesLoading) loadCoces();
			if (!departmentsLoading) loadDepartments();
			if (!usersLoading) loadUsers();
		}

		fetchData();
	}, [cocesLoading, departmentsLoading, usersLoading]);

	useEffect(() => {
		handleFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users, selectedCombinedOption, searchText]);

	const handleSelect = selectedOptions => {
		setSearchText('');
		setSelectedCombinedOption(selectedOptions);
	};

	return (
		<>
			<PageHeading
				pageTitle='Medewerkers'
				showSearchBar
				onSearch={setSearchText}
				overrideSearch={searchText}
				searchPlaceholder='Zoek een medewerker'
			/>

			<section className='page__filter'>
				<div className='page__filter-select'>
					{combinedOptions[2]?.options.length &&
					combinedOptions[3]?.options.length ? (
						<Select
							name='employee-filter-select'
							placeholder='Coce of departement'
							options={combinedOptions}
							onChange={handleSelect}
							value={selectedCombinedOption}
							validate={() => {}}
							isClearable={false}
							returnOptionsObj
						/>
					) : null}
				</div>
			</section>

			<Container className='overview'>
				{selectedCombinedOption.value !== 'ALL' ? (
					<Row className='overview__items overview__items--top'>
						{coces
							.find(
								coce =>
									coce.name === selectedCombinedOption.value,
							)
							?.cocecos.map(werknemer => (
								<Col lg={6} key={werknemer.id}>
									<Card
										title={`${werknemer.firstName} ${werknemer.lastName}`}
										url={`/medewerkers/${werknemer.slug}`}
										subTitle={`CoCeCo - ${selectedCombinedOption.value}`}
										className='overview-item-card overview__item-card--bg-dark'
										picture={
											werknemer.thumbnail
												? werknemer.thumbnail
												: userTemplate
										}
										roundedPicture
										fullSizeLink
										isHorizontal>
										<p>
											<a
												href={`mailto:${werknemer.email}`}>
												{werknemer.email}
											</a>
										</p>
									</Card>
								</Col>
							))}
					</Row>
				) : null}

				<Row className='overview__items'>
					{employees.map(werknemer => (
						<Col md={6} key={werknemer.id}>
							<Card
								title={`${werknemer.firstName} ${werknemer.lastName}`}
								url={`/medewerkers/${werknemer.slug}`}
								subTitle={
									werknemer.function?.replaceAll(
										'\\r\\n',
										'<br />',
									) ?? ''
								}
								picture={
									werknemer.thumbnail
										? werknemer.thumbnail
										: userTemplate
								}
								fullSizeLink
								roundedPicture>
								<p>
									<a href={`mailto:${werknemer.email}`}>
										{werknemer.email}
									</a>
								</p>
							</Card>
						</Col>
					))}
				</Row>
			</Container>
		</>
	);
};

export default EmployeeOverview;
