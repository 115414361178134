import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { getToken } from 'core/services/auth/getToken';
import { updateAlerts } from 'core/store/alerts/alerts.actions';
import { loadProfile } from 'core/store/authentication/auth.actions';
import { loadCars } from 'core/store/cars/car.actions';
import { loadMovingMission } from 'core/store/movingMission/movingMission.actions';
import {
	clearNotifications,
	loadNotifications,
} from 'core/store/notifications/notifications.actions';
import {
	deleteWellBeingBarometer,
	loadWellbeingBarometer,
} from 'core/store/wellbeing/wellbeing.actions';

import { loadCoces } from '../store/coces/coces.actions';
import { loadDepartments } from '../store/departments/departments.actions';
import { loadEvent, loadEvents } from '../store/events/events.actions';
import { loadMainFeedPageForItemId } from '../store/mainFeed/mainFeed.actions';
import {
	loadNewsDetails,
	loadNewsFeedPageForItemId,
} from '../store/newsFeed/newsFeed.actions';
import { loadSubFeedPageForItemId } from '../store/subFeed/subFeed.actions';
import {
	loadTraining,
	loadTrainings,
} from '../store/trainings/trainings.actions';
import { loadCocecos, loadUser, loadUsers } from '../store/users/users.actions';

const channels = [
	'privateChannel',
	'movingMission',
	'wellBeingBarometer',
	'events/trainings/news',
	'stickers',
	'users',
	'subFeed',
	'mainFeed',
	'cocecos',
	'carfleet',
	'documents',
	'coces',
	'departments',
];

const channelActionMap = {
	subFeed: loadSubFeedPageForItemId,
	mainFeed: loadMainFeedPageForItemId,
	users: loadUsers,
	carfleet: loadCars,
	wellBeingBarometer: loadWellbeingBarometer,
	movingMission: loadMovingMission,
	meObjectUpdated: loadProfile,
	cocecos: loadCocecos,
	coces: loadCoces,
	departments: loadDepartments,
	events: () => {
		loadEvents();
		loadEvent();
	},
	news: id => {
		loadNewsFeedPageForItemId(id);
		loadNewsDetails();
	},
	trainings: () => {
		loadTrainings();
		loadTraining();
	},
};

const handleMessage = message => {
	const { type: channel, data } = JSON.parse(message);

	if (channel === 'stickers') {
		if (channelActionMap[data.objectType]) {
			channelActionMap[data.objectType](data.id);
			if (data.objectType !== 'subFeed') {
				loadMainFeedPageForItemId(data.id);
			}
		}
	} else if (channel === 'privateChannel') {
		if (data.type === 'alerts') {
			const { alertType, action, ...alertData } = data;
			updateAlerts({ ...alertData, type: alertType }, action);
		} else if (data.type === 'notifications') {
			if (data.action === 'remove') {
				clearNotifications();
			} else {
				loadNotifications();
			}
		} else {
			channelActionMap[data.type]?.();
		}
	} else if (channel === 'wellBeingBarometer') {
		if (data.type === 'wellBeingBarometerClosed') {
			deleteWellBeingBarometer(data.id);
		}
	} else if (channel === 'users') {
		if (data.type === 'userUpdated') {
			loadUser(data.id);
		}
	} else {
		if (channelActionMap[channel]) {
			channelActionMap[channel]();
		}
	}
};

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
	const [isReady, setIsReady] = useState(false);
	const [token, setToken] = useState(null);

	const ws = useRef(null);
	const isAuthenticated = useIsAuthenticated();

	const sendMessage = message => {
		if (ws.current && ws.current.readyState === WebSocket.OPEN) {
			ws.current.send(message);
		}
	};

	const subscribeToAllchannels = useCallback(() => {
		channels.forEach(channel => {
			sendMessage(JSON.stringify({ type: 'subscribe', data: channel }));
		});
	}, []);

	const connect = useCallback(() => {
		ws.current = new WebSocket(
			`${process.env.REACT_APP_NODE_WEBSOCKET_URL}?token=${token}`,
		);

		ws.current.onopen = () => {
			//console.log('WEBSOCKET OPEN');
			setIsReady(true);
			setTimeout(subscribeToAllchannels, 1000);
		};

		ws.current.onmessage = event => {
			handleMessage(event.data);
		};

		// eslint-disable-next-line no-unused-vars
		ws.current.onerror = error => {
			//console.error('WEBSOCKET ERROR', error);
			setIsReady(false);
		};

		ws.current.onclose = () => {
			//console.log('WEBSOCKET CLOSED');
			setIsReady(false);
			ws.current = null;
			setTimeout(connect, 30000); // try to reconnect after 30 seconds
		};
	}, [subscribeToAllchannels, token]);

	useEffect(() => {
		if (token) {
			connect();
		}
		return () => {
			if (ws.current) {
				ws.current.close();
			}
		};
	}, [token, connect]);

	useEffect(() => {
		if (isAuthenticated) {
			getToken().then(setToken);
		}
	}, [isAuthenticated]);

	return (
		<WebSocketContext.Provider
			value={{
				isReady,
				sendMessage,
			}}>
			{children}
		</WebSocketContext.Provider>
	);
};

/**
 * @typedef {( 'usersUpdated' | 'MeObjectUpdated' | 'subFeedUpdated' | 'eventCreated' | 'newsCreated' | 'stickerCreated' | 'trainingCreated' | 'mainFeedCreated' | 'mainFeedDeleted' | 'subFeedDeleted' | 'notificationSetToseen' | 'notifications' )} WebSocketMessageType
 */

/**
 * @typedef {('subFeed' | 'mainFeed' | 'users' | 'events' | 'news' | 'stickers' | 'training' | 'notifications' | 'privateChannel')} WebSocketChannelType
 */

/**
 * @typedef {('subFeed'|'news'|'training'|'events')} WebSocketMessageStickerType
 */
