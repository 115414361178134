import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components/Form';

import { calendarTranslation, getCalendarLabel } from './calendar.service';

const CalendarToolbar = ({ date, onNavigate, onView, views, view }) => {
	return (
		<div className='calendar__toolbar'>
			<div className='btn__group'>
				{views.map(x => (
					<Button
						buttonStyle={view === x ? 'primary' : 'secondary'}
						onClick={() => onView(x)}
						label={calendarTranslation[x]}
						buttonSize='small'
						styles={x === 'subscribe' ? { marginLeft: '2rem' } : {}}
						key={x}
					/>
				))}
			</div>

			<div className='calendar__toolbar--header'>
				<div className='calendar__toolbar--header_label'>
					{getCalendarLabel(view, date)}
				</div>
				<div className='btn__group'>
					<Button
						buttonStyle='secondary'
						onClick={() => onNavigate('PREV')}
						label={<FontAwesomeIcon icon={faBackward} fixedWidth />}
						buttonSize='small'
						disabled={view === 'subscribe'}
					/>
					<Button
						buttonStyle='secondary'
						onClick={() => onNavigate('TODAY')}
						label='Vandaag'
						buttonSize='small'
						disabled={view === 'subscribe'}
					/>
					<Button
						buttonStyle='secondary'
						onClick={() => onNavigate('NEXT')}
						label={<FontAwesomeIcon icon={faForward} fixedWidth />}
						buttonSize='small'
						disabled={view === 'subscribe'}
					/>
				</div>
			</div>
		</div>
	);
};

export default CalendarToolbar;
