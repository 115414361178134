import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';
import { getYearAndMonth } from 'core/services/date';

export const createAbsence = async date => {
	try {
		const {
			data: { data, status },
		} = await executeAxios({
			url: '/absences',
			method: 'POST',
			data: {
				date,
			},
		});
		handleAlert('success', 'Afwezigheid is succesvol toegevoegd.');
		return status === 'success' ? data : null;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het toevoegen van de afwezigheid.',
		);
		return null;
	}
};

export const updateAbsence = async absence => {
	try {
		const {
			data: { data, status },
		} = await executeAxios({
			url: `/absences/${absence.id}`,
			method: 'PUT',
			data: {
				date: absence.date,
				hours: absence.duration,
				type: absence.absenceTypeId,
				remark: absence.remark || '',
			},
		});
		handleAlert('success', 'Afwezigheid is succesvol aangepast.');
		return status === 'success' ? data : null;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het aanpassen van de afwezigheid.',
		);
		return null;
	}
};

export const deleteAbsence = async absence => {
	try {
		const { status } = await executeAxios({
			url: `/absences/${absence.id}`,
			method: 'DELETE',
		});
		handleAlert('success', 'Afwezigheid is succesvol verwijderd.');
		return status === 204;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het verwijderen van de afwezigheid.',
		);
		return false;
	}
};

export const fetchAbsences = async currentDate => {
	const url = '/absences';
	const params = getYearAndMonth(currentDate);
	try {
		const {
			data: { data },
		} = await executeAxios({ url, params });
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de afwezigheden.',
		);
		return null;
	}
};

export const getCounters = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/absences/counters',
		});
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van de afwezigheden.',
		);
	}
};

export const loadOverview = async () => {
	try {
		const {
			data: { data },
		} = await executeAxios({
			url: '/absences/all',
		});
		return data;
	} catch (error) {
		handleAlert(
			'error',
			'Er is iets misgegaan bij het ophalen van het overzicht.',
		);
		return null;
	}
};

export const getHtmlElement = (counters, requested) => {
	let total = 0;

	const elements = counters
		.filter(x => x.type.machineName !== 'educational-leave')
		.map(x => {
			if (x.type.machineName === 'bank-holiday') {
				total +=
					(x.prediction?.prediction?.availableNow ?? 0) +
					(x.prediction?.prediction?.predicted ?? 0);
			} else {
				total += x.prediction?.counter ?? 0;
			}

			if (x.type.machineName === 'seniority-leave') {
				total += x.prediction?.prediction?.predicted ?? 0;
			}

			return getElement(x);
		});

	return (
		<>
			{elements.map(x => x)}
			<div className='total'>
				<span>Totaal</span>
				<span>{total.toFixed(2).replace('.', ',')} u</span>
			</div>
			<div>
				<span>Aangevraagd</span>
				<span>
					{requested !== 0
						? `- ${requested.toFixed(2).replace('.', ',')}`
						: 0}{' '}
					u
				</span>
			</div>
			<div className='total'>
				<span>Totaal</span>
				<span>
					{(total - requested).toFixed(2).replace('.', ',')} u
				</span>
			</div>
		</>
	);
};

export const getElement = counter => {
	let current, other;

	if (counter.type.machineName === 'bank-holiday') {
		current = (
			<div>
				<span>{counter.type.name}: huidige teller</span>
				<span>
					{(counter.prediction?.prediction?.availableNow ?? 0)
						.toFixed(2)
						.replace('.', ',')}{' '}
					u
				</span>
			</div>
		);

		other = (
			<div>
				<span>{counter.type.name}: toekomstig vrij te kiezen</span>
				<span>
					{(counter.prediction?.prediction?.predicted ?? 0)
						.toFixed(2)
						.replace('.', ',')}{' '}
					u
				</span>
			</div>
		);
	} else {
		current = (
			<div>
				<span>{counter.type.name}: huidige teller</span>
				<span>
					{(counter.prediction?.counter ?? 0)
						.toFixed(2)
						.replace('.', ',')}{' '}
					u
				</span>
			</div>
		);
	}

	if (counter.type.machineName === 'seniority-leave') {
		other = (
			<div>
				<span>{counter.type.name}: toekomstig te verkrijgen</span>
				<span>
					{(counter.prediction?.prediction?.predicted ?? 0)
						.toFixed(2)
						.replace('.', ',')}{' '}
					u
				</span>
			</div>
		);
	}

	return (
		<>
			{current}
			{other}
		</>
	);
};
