import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
	faArrowLeftLong,
	faInfoCircle,
	faList,
	faSignature,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageHeading } from 'components/Layouts/PageHeading/PageHeading';
import Modal from 'components/Modal/Modal';
import { SecondaryNavigation } from 'components/Navigation/SecondaryNavigation/SecondaryNavigation';

import './UploadTimesheets.scss';

export const UploadTimesheets = () => {
	const navigate = useNavigate();
	const [infoModal, setInfoModal] = useState(false);
	const [infoText, setInfoText] = useState('');

	const { authUser } = useSelector(state => state.authReducer);

	const tabs = [
		{ id: 1, path: 'overzicht', name: 'Overzicht', icon: faList },
		{
			id: 2,
			path: 'handtekening',
			name: 'Handtekening',
			icon: faSignature,
		},
	];

	useEffect(() => {
		if (authUser?.signature) {
			navigate('overzicht');
		} else {
			navigate('handtekening');
		}
	}, []);

	let location = useLocation();

	useEffect(() => {
		let screen = location.pathname.split('/').pop();

		switch (screen) {
			case 'handtekening':
				setInfoText(
					<>
						<h4>Handtekening</h4>Deze handtekening wordt gebruikt om
						jouw timesheets te tekenen. Upload of teken een
						handtekening en sla deze op. Je moet nu geen timesheet
						meer uploaden die door jou getekend is. Een getekende
						timesheet door de eindklant is wel nog nodig.
					</>,
				);
				break;
			case 'overzicht':
				setInfoText(
					<>
						<h4>Opladen timesheet</h4>Gebruik de knop “Timesheet
						opladen” om een pdf- of afbeeldingsversie van je
						ondertekende timesheet te uploaden. Ga als volgt te
						werk: <br /> 1. Selecteer je project waar je een
						timesheet voor wil opladen (enkel van toepassing waneer
						je meerdere projecten hebt). <br /> 2. Upload een
						digitale versie van je ondertekende timesheet.
						<br /> 3. Geef aan of jij de timesheet hebt ondertekend
						(optioneel).
						<br /> 4. Geef aan of de timesheet door je eindklant is
						ondertekend. <h4>Maand indienen</h4> Dit is de laatste
						stap van het proces. Wanneer je je getekende timesheets
						hebt opgeladen en je uren correct hebt ingedient klik je
						op “Maand indienen”. Al je gegevens worden nu verstuurd
						naar de administratie.
					</>,
				);
				break;
			default:
				setInfoText('');
				break;
		}
	}, [location]);

	const handleInfo = () => {
		setInfoModal(true);
	};

	return (
		<>
			<div className='upload-timesheets'>
				<PageHeading
					pageTitle={
						location.pathname.split('/').pop() === 'registratienota'
							? 'Registratienota'
							: 'Timesheets'
					}
					extraChildrenInsideInleiding={
						<SecondaryNavigation arrayOfTabs={tabs} />
					}
					extraChildrenInsideHeader={
						<button className='btn infobtn' onClick={handleInfo}>
							<FontAwesomeIcon icon={faInfoCircle} size='xl' />
						</button>
					}
				/>
				<h5 className='upload-timesheets__go-back'>
					<Link
						to={`/timesheet${location.pathname.split('/').pop() === 'registratienota' ? '/tekenen/overzicht' : ''}`}>
						<FontAwesomeIcon
							icon={faArrowLeftLong}
							className='icon'
							fixedWidth
						/>{' '}
						Terug naar{' '}
						{location.pathname.split('/').pop() ===
						'registratienota'
							? 'overzicht'
							: 'timesheets'}
					</Link>
				</h5>
				<Outlet />
			</div>
			<Modal
				title='Info'
				show={infoModal}
				close={() => setInfoModal(false)}>
				{infoText}
			</Modal>
		</>
	);
};

export default UploadTimesheets;
