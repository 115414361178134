import React from 'react';
import { NavLink } from 'react-router-dom';
import {
	faChalkboardUser,
	faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DisplayOrganisors } from 'components/DisplayOrganisors/DisplayOrganisors';
import ItemCard from 'components/ListOverview/ListOverviewItem';

import './DetailSideBar.scss';

export const DetailSideBar = ({ itemToShow, organisors, type }) => {
	return (
		<section className='sidebar-container'>
			<ItemCard item={itemToShow} type={type} showTitle={false}>
				{organisors ? (
					<DisplayOrganisors organisors={organisors} />
				) : null}
				{itemToShow.instructor ? (
					<InstructorItem instructor={itemToShow.instructor} />
				) : null}
				{itemToShow.isAddedToCV ? <CVInfoItem /> : null}
			</ItemCard>
		</section>
	);
};

const InstructorItem = ({ instructor }) => {
	return (
		<section className='card_row'>
			<span className='sidebar-icon'>
				<FontAwesomeIcon icon={faChalkboardUser} />
			</span>
			<span className='instructor__text'>
				<NavLink
					key={instructor.id}
					to={`/medewerkers/${instructor.id}`}>
					{instructor.firstName} {instructor.lastName}
				</NavLink>
			</span>
		</section>
	);
};

const CVInfoItem = () => {
	return (
		<section className='card_row'>
			<span className='sidebar-icon'>
				<FontAwesomeIcon icon={faFileSignature} />
			</span>
			<span className='added-to-cv__text'>Toegevoegd aan je CV-UP</span>
		</section>
	);
};
