/**
 * @param value
 * @param index
 * @param self
 * @returns {boolean}
 */
export const unique = (value, index, self) => {
	return self.indexOf(value) === index;
};

export const arraySum = (array, key = null) => {
	let amount = 0;

	array.forEach(item => {
		if (typeof item === 'number') {
			amount += item || 0;
		} else if (key) {
			amount += Number(item[key] || 0);
		}
	});

	return amount;
};
