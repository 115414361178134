import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSubmit } from 'core/hooks/useSubmit';
import { getToppings } from 'core/store/toppings/topping.actions';
import { updateTrainingOrder } from 'core/store/trainings/trainings.actions';

import { Textarea, useField } from 'components/Form';
import { postForm } from 'components/Form/FormModal/formModal.service';
import { Col, Container, Row } from 'components/Grid';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal/Modal';

import TrainingFormDay from './TrainingFormDay/TrainingFormDay';

import './TrainingFormModal.scss';

const TrainingForm = ({ modal, training, setModal }) => {
	const { toppings } = useSelector(state => state.toppingReducer);
	const { order } = training;

	useEffect(() => {
		if (!toppings || toppings.length === 0) {
			getToppings();
		}
	}, []);

	const [formData, setFormData] = useState([]);

	const remark = useField({
		type: 'textarea',
		name: 'remark',
		label: 'Opmerking',
		validations: [],
		errors: [],
		value: order?.remark || '',
	});

	const handleSubmit = useSubmit(async () => {
		if (formData.every(data => data.valid)) {
			const payload = {
				trainingId: training.id,
				results: formData
					.filter(form => form.topping || form.bread !== undefined)
					.map(({ date, bread, size, extra, topping }) => ({
						date: date,
						bread: !bread,
						...(!bread
							? {
									size: size,
									extra: extra ?? false,
									topping: topping,
								}
							: {}),
					})),
				remark: remark.value,
			};

			const posted = await postForm(payload);

			if (posted) {
				if (modal?.postSubmitCallback) {
					await modal.postSubmitCallback();
				}
				updateTrainingOrder({
					days: posted.results,
					remark: posted.remark,
				});
				setModal(false);
			}
		}
	});

	const getSizeOptions = count => {
		return {
			md: 6,
			...(count > 2 ? { lg: 4 } : count > 1 ? { lg: 6 } : { lg: 12 }),
		};
	};

	return toppings ? (
		<Modal
			className='training-modal'
			show={true}
			close={() => setModal(null)}
			title={training.name}
			actions={[
				{
					label: 'Verstuur Formulier',
					styles: { marginLeft: '1rem' },
					disabled: !formData.every(data => data.valid),
					action: handleSubmit,
				},
			]}>
			<Container>
				<Row>
					{training?.days.map((day, i, days) => (
						<Col key={i} {...getSizeOptions(days.length)}>
							<TrainingFormDay
								key={i}
								day={day}
								order={order ? order?.days[i] : null}
								toppings={toppings}
								setFormData={setFormData}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg={12}>
						<div className='form-day'>
							<Textarea {...remark} />
						</div>
					</Col>
				</Row>
			</Container>
		</Modal>
	) : (
		<Loading />
	);
};

export default TrainingForm;
