import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';

import { Button, Upload, useForm } from 'components/Form';

import './TimesheetSignature.scss';

const TimesheetSignature = () => {
	const [signatureWidth, setSignatureWidth] = useState(0);
	const [trimmedDataURL, setTrimmedDataURL] = useState(null);
	const [showUpload, setShowUpload] = useState(false);
	const [showActionButtons, setShowActionButtons] = useState(false);

	const sigCanvas = useRef(null);
	const container = useRef(null);
	const navigate = useNavigate();

	const clear = () => sigCanvas.current?.clear();

	const trim = () =>
		setTrimmedDataURL(
			sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png'),
		);

	const { register, setValue, getValue } = useForm({
		onFormChange: ({ signature }) => {
			const image = signature[0];
			const reader = new FileReader();
			reader.readAsDataURL(image);
			reader.onloadend = () => {
				setTrimmedDataURL(reader.result);
				setShowActionButtons(true);
			};
		},
	});

	const setSize = () => {
		setSignatureWidth(container.current?.getBoundingClientRect().width);
	};

	const handleCancel = () => {
		clear();
		setTrimmedDataURL(null);
		setShowActionButtons(false);
		if (getValue('signature')) {
			setValue('signature', []);
		}
	};

	const uploadSignature = async () => {
		try {
			const {
				data: { status },
			} = await executeAxios({
				method: 'PUT',
				url: '/auth/me/signature',
				data: { image: trimmedDataURL },
			});
			if (status === 'success') {
				setValue('signature', []);
				setTrimmedDataURL(null);
				setShowActionButtons(false);
				handleAlert('success', 'Handtekening succesvol toegevoegd');
				navigate('/timesheet/tekenen/overzicht');
			}
		} catch (error) {
			handleAlert('error', 'Er is iets misgegaan');
			console.log(error);
		}
	};

	useEffect(() => {
		setSize();
		window.addEventListener('resize', setSize);
	}, []);

	return (
		<div className='timesheet-signature' ref={container}>
			{trimmedDataURL ? (
				<div className='timesheet-signature__preview'>
					<span className='label'>Handtekening overzicht</span>
					<img src={trimmedDataURL} />
				</div>
			) : (
				<div className='timesheet-signature__fields'>
					{showUpload ? (
						<Upload
							{...register('signature', {
								label: 'Upload je handtekening',
								accept: 'image/*',
								required: true,
								maxSize: 2000000,
								description:
									'Door een handtekening te plaatsen, worden alle documenten die door KetchUP worden gegenereerd automatisch ondertekend.\nEnkel afbeeldingen kleiner dan 2MB zijn toegelaten',
								value: [],
							})}
						/>
					) : (
						<div className='signature-container'>
							<span className='label'>
								Plaats je handtekening
							</span>
							<ReactSignatureCanvas
								ref={sigCanvas}
								penColor='black'
								onEnd={() => setShowActionButtons(true)}
								canvasProps={{
									width: signatureWidth,
									height: 200,
									className: 'sig-canvas',
								}}
							/>
							<div className='info'>
								Door een handtekening te plaatsen, worden alle
								documenten die door KetchUP worden gegenereerd
								automatisch ondertekend.
							</div>
						</div>
					)}
				</div>
			)}
			<div className='timesheet-signature__buttons'>
				{showActionButtons ? (
					<div className='timesheet-signature__buttons__actions'>
						<Button buttonStyle='secondary' onClick={handleCancel}>
							Annuleren
						</Button>
						{trimmedDataURL ? (
							<Button
								buttonStyle='primary'
								onClick={uploadSignature}>
								Toevoegen
							</Button>
						) : (
							<Button buttonStyle='primary' onClick={trim}>
								Doorgaan
							</Button>
						)}
					</div>
				) : (
					<div className='timesheet-signature__buttons__ways'>
						<p>of</p>
						{showUpload ? (
							<Button
								buttonStyle='secondary'
								onClick={() => setShowUpload(false)}>
								Plaats je handtekening
							</Button>
						) : (
							<Button
								buttonStyle='secondary'
								onClick={() => setShowUpload(true)}>
								Laad een handtekening op
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default TimesheetSignature;
