import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadEvent } from 'core/store/events/events.actions';

import { DetailPageLayout } from 'components/Layouts/DetailPageLayout/DetailPageLayout';
import Loading from 'components/Loading/Loading';

import EventFormModal from '../EventFormModal/EventFormModal';

import './EventDetail.scss';

const EventDetail = () => {
	const { slug } = useParams();
	const { eventDetails } = useSelector(state => state.eventReducer);
	const [modal, setModal] = useState(null);

	useEffect(() => {
		if (slug) {
			loadEvent(slug);
		}
	}, [slug]);

	return eventDetails ? (
		<>
			<DetailPageLayout
				id={eventDetails.id}
				itemToShow={eventDetails}
				title={eventDetails.name}
				feedDetail={eventDetails}
				creator={`${eventDetails.creator.firstName} ${eventDetails.creator.lastName}`}
				createdAt={eventDetails.publishedAt}
				slug='evenementen'
				days={eventDetails.days}
				organisors={eventDetails.organisers}
				location={eventDetails.location}
				userInvitedStatus={eventDetails.status}
				lastDay={eventDetails.maxAttendDate}
				maxAttendees={eventDetails.maxAttendees}
				type='events'
				showForm={eventDetails.hasForm ? setModal : null}
			/>
			{modal ? (
				<EventFormModal
					modal={modal}
					setModal={setModal}
					eventId={eventDetails.id}
				/>
			) : null}
		</>
	) : (
		<Loading />
	);
};
export default EventDetail;
