import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	faBirthdayCake,
	faBriefcase,
	faGifts,
	faGlassCheers,
	faGraduationCap,
	faPlaneDeparture,
	faRankingStar,
	faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CalendarDay = ({ event }) => {
	const { icon, slug } = useMemo(() => {
		switch (event.type) {
			case 'leave':
				return {
					slug: '/timesheet/afwezigheden',
					icon: faPlaneDeparture,
				};
			case 'events':
				return {
					slug: `/evenementen/${event.id}`,
					icon: faGlassCheers,
				};
			case 'trainings':
				return {
					slug: `/opleidingen/${event.id}`,
					icon: faGraduationCap,
				};
			case 'holiday':
				return {
					slug: '/timesheet/afwezigheden',
					icon: faGifts,
				};
			case 'birthday':
				return {
					slug: `/medewerkers/${event.extra.slug}`,
					icon: faBirthdayCake,
				};
			case 'years_of_service':
				return {
					slug: `/medewerkers/${event.extra.slug}`,
					icon: faStar,
				};
			case 'moving_mission':
				return {
					slug: '/moving-mission',
					icon: faRankingStar,
				};
			case 'project':
			default:
				return {
					slug: '/timesheet/urenregistratie',
					icon: faBriefcase,
				};
		}
	}, [event.type]);

	return (
		<Link to={slug}>
			<div className={`status${event.status ? ` ${event.status}` : ''}`}>
				<FontAwesomeIcon icon={icon} fixedWidth />
			</div>
			{event.title}
		</Link>
	);
};

export default CalendarDay;
