import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export const calendarTranslation = {
	allDay: 'hele dag',
	previous: 'vorige',
	next: 'volgende',
	today: 'vandaag',
	month: 'Maand',
	week: 'Week',
	work_week: 'Werkweek',
	day: 'Dag',
	agenda: 'Agenda',
	subscribe: 'Abonneer',
	date: 'datum',
	time: 'uur',
	event: 'evenement',
	showMore: total => `+ ${total} meer`,
};

export const calendarFormats = {
	dayFormat: (date, culture, localizer) =>
		localizer.format(date, 'dd D', culture),
	weekdayFormat: (date, culture, localizer) =>
		localizer.format(date, 'dd', culture),
	dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
		localizer.format(start, 'D MMM YYYY', culture) +
		' - ' +
		localizer.format(end, 'D MMM YYYY', culture),
	agendaHeaderFormat: ({ start, end }, culture, localizer) =>
		localizer.format(start, 'D MMM YYYY', culture) +
		' - ' +
		localizer.format(end, 'D MMM YYYY', culture),
	dayHeaderFormat: (date, culture, localizer) =>
		localizer.format(date, 'dddd D MMM YYYY', culture),
	agendaDateFormat: (date, culture, localizer) =>
		localizer.format(date, 'dd D MMM', culture),
};

export const getCalendarLabel = (view, date) => {
	switch (view) {
		case 'subscribe':
			return 'Abonneer';
		case 'day':
			return (
				<>
					{format(date, 'd MMMM', { locale: nl })}{' '}
					<span>{date.getFullYear()}</span>
				</>
			);
		case 'week':
		case 'work_week':
		case 'month':
		default:
			return (
				<>
					{format(date, 'MMMM', { locale: nl })}{' '}
					<span>{date.getFullYear()}</span>
				</>
			);
	}
};
