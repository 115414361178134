import { ACTION_TYPES } from 'core/constants/action.constants/action.constants';
import { dispatchAction } from 'core/services/actions';
import { toTechnicalDate } from 'core/services/date';

import { store } from '../store.config';

export const loadCalendar = (date, view) => {
	const state = store.getState();
	const { calendarLoading } = state.calendarReducer;

	if (!calendarLoading) {
		const params = { date: toTechnicalDate(date), view };

		dispatchAction({
			type: ACTION_TYPES.CALENDAR_LOAD,
			payload: { calendarLoading: true },
			meta: {
				action: {
					effect: {
						url: `/calendar?date=${params.date}&view=${params.view}`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.CALENDAR_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.CALENDAR_LOAD,
						payload: { calendarLoading: false },
					},
				},
			},
		});
	}
};
