import { useState } from 'react';
import {
	any,
	array,
	arrayOf,
	bool,
	func,
	number,
	object,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';
import WorkedHours from '../WorkedHours/WorkedHours';

const InputField = ({
	type = 'text',
	name,
	label = '',
	placeholder = '',
	icon,
	button = {
		icon: null,
		baseValue: 1,
		options: [],
		useWholeNumbers: false,
		action: null,
	},
	description = '',
	value = '',
	required = false,
	readOnly = false,
	disabled = false,
	autocomplete = false,
	validations = [],
	validate,
	errors = [],
	onChange,
	className,
	onKeyDown,
	step = '1',
	reset = false,
}) => {
	const { focused, id, ...handlers } = useFormInput({
		name,
		value,
		validate,
		onChange,
		errors,
		validations,
		reset,
	});

	const [popupOpen, setPopupOpen] = useState(false);

	const handleButtonClick = e => {
		e.preventDefault();
		e.stopPropagation();

		if (button?.action) {
			button.action();
		} else {
			setPopupOpen(prev => !prev);
		}
	};

	const handleSelect = selected => {
		const calculatedValue = selected * button.baseValue;
		onChange(
			button.useWholeNumbers
				? calculatedValue
				: calculatedValue.toFixed(2),
		);
		setPopupOpen(false);
	};

	const extraProps = {
		...(type === 'number' && { step }),
	};

	return (
		<UiInput
			hasValue={value ? value.length > 0 : null}
			errorMsgs={errors || []}
			label={label}
			required={required}
			description={description}
			icon={icon}
			buttonIcon={button?.icon}
			buttonClickAction={handleButtonClick}
			className={className}
			focused={focused}
			disabled={disabled}
			id={id}>
			<input
				type={type}
				id={id}
				value={value ?? null}
				placeholder={`${placeholder}${
					placeholder && required && !label ? ' *' : ''
				}`}
				{...handlers}
				autoComplete={autocomplete ? autocomplete : 'off'}
				readOnly={readOnly}
				disabled={disabled}
				onKeyDown={onKeyDown}
				{...extraProps}
			/>
			{button?.icon && popupOpen ? (
				<WorkedHours
					onSelect={handleSelect}
					options={button.options}
					close={() => setPopupOpen(false)}
				/>
			) : null}
		</UiInput>
	);
};

InputField.propTypes = {
	type: string,
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number]),
	required: bool,
	readOnly: bool,
	disabled: bool,
	icon: object,
	button: shape({
		icon: object,
		baseValue: number,
		useWholeNumbers: bool,
		options: arrayOf(
			shape({
				label: string,
				value: oneOfType([string, number]),
			}),
		),
		action: func,
	}),
	autocomplete: any,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	step: string,
	reset: bool,
};

export default InputField;
