import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import {
	Account,
	ContactsPage,
	Documents,
	EmployeeDetail,
	EmployeeOverview,
	EventDetail,
	EventOverview,
	Home,
	MyCar,
	NewsDetail,
	NewsOverview,
	OnkostenView,
	RegistrationNote,
	Timesheet,
	TrainingDetail,
	TrainingOverview,
	UploadTimesheets,
	UserFeed,
	VacationView,
} from 'views';
import Calendar from 'views/Calendar/Calendar';
import {
	MovingMission,
	MovingMissionAchievements,
	MovingMissionOverview,
} from 'views/MovingMission';
import { GlasSchade } from 'views/MyCar/GlasSchade/GlasSchade';
import { MijnWagen } from 'views/MyCar/MijnWagen/MijnWagen';
import { Ongeval } from 'views/MyCar/Ongeval/Ongeval';
import { Panne } from 'views/MyCar/Panne/Panne';
import ReportSickness from 'views/ReportSickness/ReportSickness';
import ReportSicknessPage from 'views/ReportSickness/ReportSicknessPage';

import { UpdateInstellingen } from 'components/Account/UpdateInstellingen/UpdateInstellingen';
import { UpdatePicture } from 'components/Account/UpdatePicture/UpdatePicture';
import UpdateProfile from 'components/Account/UpdateProfile/UpdateProfile';
import NotFound from 'components/Error/NotFound/NotFound';
import TimesheetOverview from 'components/Timesheet/Overview/TimesheetOverview';
import TimesheetSignature from 'components/Timesheet/Signature/TimesheetSignature';
import { TimesheetForm } from 'components/Timesheet/TimeSheetForm/TimesheetForm';
import Warning from 'components/Warning/Warning';

const LayoutRouter = () => {
	const { settings } = useSelector(state => state.authReducer);

	return (
		<Routes>
			<Route index element={<Home />} />
			<Route exact path='/account' element={<Account />}>
				<Route index element={<Navigate to='instellingen' replace />} />
				<Route path='instellingen' element={<UpdateInstellingen />} />
				<Route path='profiel' element={<UpdateProfile />} />
				<Route path='afbeelding' element={<UpdatePicture />} />
			</Route>
			<Route exact path='/documenten' element={<Documents />} />
			<Route exact path='/user-feed' element={<UserFeed />} />
			<Route exact path='/contacten' element={<ContactsPage />} />

			<Route path='/opleidingen' element={<Outlet />}>
				<Route index element={<TrainingOverview />} />
				<Route path=':slug' element={<TrainingDetail />} />
			</Route>

			<Route path='/evenementen' element={<Outlet />}>
				<Route index element={<EventOverview />} />
				<Route path=':slug' element={<EventDetail />} />
			</Route>

			<Route path='/nieuws' element={<Outlet />}>
				<Route index element={<NewsOverview />} />
				<Route path=':slug' element={<NewsDetail />} />
			</Route>

			<Route path='/medewerkers' element={<Outlet />}>
				<Route index element={<EmployeeOverview />} />
				<Route path=':slug' element={<EmployeeDetail />} />
			</Route>

			<Route path='/meld-ziekte' element={<Outlet />}>
				<Route index element={<ReportSicknessPage />} />
				<Route path=':id' element={<ReportSicknessPage />} />
			</Route>

			{settings?.hasCar ? (
				<Route path='/wagen' element={<MyCar />}>
					<Route index element={<Navigate to='ongeval' replace />} />
					<Route path='mijn-wagen' element={<MijnWagen />} />
					<Route path='ongeval' element={<Ongeval />}>
						<Route path=':id' element={<Ongeval />} />
					</Route>
					<Route
						path='panne-of-glasschade'
						element={
							<>
								<GlasSchade />
								<Panne />
							</>
						}
					/>
				</Route>
			) : null}

			<Route path='timesheet' element={<Timesheet />}>
				<Route path='urenregistratie' element={<TimesheetForm />} />
				<Route path='ziekte-meldingen' element={<ReportSickness />}>
					<Route path=':id' element={<ReportSickness />} />
				</Route>
				<Route path='afwezigheden' element={<VacationView />} />
				<Route path='onkostennotas' element={<OnkostenView />}>
					<Route path=':id' element={<OnkostenView />} />
				</Route>
			</Route>

			<Route path='/timesheet/tekenen' element={<UploadTimesheets />}>
				<Route path='overzicht' element={<TimesheetOverview />} />
				<Route path='handtekening' element={<TimesheetSignature />} />
				<Route path='registratienota' element={<RegistrationNote />} />
			</Route>

			<Route path='/moving-mission' element={<MovingMission />}>
				<Route index element={<Navigate to='overzicht' replace />} />
				<Route path='overzicht' element={<MovingMissionOverview />} />
				<Route
					path='je-prestaties'
					element={<MovingMissionAchievements />}
				/>
			</Route>

			<Route path='/kalender' element={<Calendar />} />

			<Route path='*' element={<NotFound />} />
		</Routes>
	);
};

// eslint-disable-next-line no-unused-vars
const Alert = () => {
	return (
		<Warning className='error'>
			<b>Opgelet!</b>
			<br />
			Timesheets zijn momenteel uitgeschakeld.
		</Warning>
	);
};

export default LayoutRouter;
